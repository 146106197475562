.organizationchart-demo {
    .p-organizationchart {
        .p-person {
            padding: 0;
            border: 0 none;
        }

        .node-header, .node-content {
            padding: .5em .7rem;
        }

        .node-header {
            background-color: #495ebb;
            color: #ffffff;
        }

        .node-content {
            text-align: center;
            border: 1px solid #495ebb;
        }

        .node-content img {
            border-radius: 50%;
        }

        .department-cfo {
            background-color: #7247bc;
            color: #ffffff;
        }

        .department-coo {
            background-color: #a534b6;
            color: #ffffff;
        }

        .department-cto {
            background-color: #e9286f;
            color: #ffffff;
        }
    }
}
