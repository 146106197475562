.scrollpanel-demo {
    .p-scrollpanel {
        p {
            padding: .5rem;
            line-height: 1.5;
            margin: 0;
        }

        &.custombar1 {
            .p-scrollpanel-wrapper {
                border-right: 10px solid var(--surface-b);
            }

            .p-scrollpanel-bar {
                background-color: var(--primary-color);
                opacity: 1;
                transition: background-color .2s;

                &:hover {
                    background-color: #007ad9;
                }
            }
        }

        &.custombar2 {
            .p-scrollpanel-wrapper {
                border-right: 10px solid var(--surface-b);
                border-bottom: 10px solid var(--surface-b);
            }

            .p-scrollpanel-bar {
                background-color: var(--surface-d);
                border-radius: 0;
                opacity: 1;
                transition: background-color .2s;
            }
        }
    }

    .p-col-12 {
        padding: 2rem;
    }
}
